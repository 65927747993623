<template>
  <span class="red--text"><strong> * </strong></span>
</template>

<script>
export default {
  name: "f2gRedAsterisk",

  
};
</script>

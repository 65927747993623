<template>
  <v-select
    :disabled="disabled"
    :error-messages="errorMessages"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :return-object="returnObject"
    v-model="vModelToShow"
    @change="changeEvent"
    ><template slot="label">
      {{ label }}<RedAsterisk v-if="required" />
    </template>
    <template slot="append-outer">
      <slot name="append-outer"> </slot>
    </template>
  </v-select>
</template>
<script>
import RedAsterisk from "@/flit2go-web-core/components/fields/f2g-red-asterisk";
export default {
  name: "F2g-select",
  components: {
    RedAsterisk,
  },
  props: {
    disabled: { type: Boolean, required: false, default: false },
    errorMessages: { type: Array, required: false },
    items: { type: Array, required: true },
    itemText: { type: String, required: false, default: "" },
    itemValue: { type: String, required: false, default: "" },
    returnObject: { type: Boolean, required: false, default: false },
    value: { type: [Object, String, Number], required: false },
    label: { type: String, required: false, default: "" },
    required: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    vModelToShow: {},
  }),
  mounted() {
    if (this.value) {
      this.vModelToShow = this.value;
    }
  },
  watch: {
    value() {
      this.vModelToShow = this.value;
    },
  },
  methods: {
    touchField() {
      if (this.required) this.$emit("touch");
    },
    changeEvent() {
      this.$emit("input", this.vModelToShow);
      this.$emit("change", this.vModelToShow);
    },
  },
};
</script>
